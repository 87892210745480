import { JobQueueView } from "src/components/views/erp/production/jobQueue/base/JobQueueView.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { openLegacyWorkcenterOperationActionView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { SelectedOperationActionsViewExtraComponentProps } from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationActionsView.tsx";
import { ProductionLineGroupJobQueueCompletedApi } from "src/api/generated/erp/production/api/jobQueues/productionLineGroup/productionLineGroupJobQueueCompletedApi.ts";
import { ProductionLineGroupJobQueueInProgressApi } from "src/api/generated/erp/production/api/jobQueues/productionLineGroup/productionLineGroupJobQueueInProgressApi.ts";
import { ProductionLineGroupJobQueueInitialsApi } from "src/api/generated/erp/production/api/jobQueues/productionLineGroup/productionLineGroupJobQueueInitialsApi.ts";

export const ProductionLineGroupJobQueueView = () => {
	return (
		<JobQueueView
			viewId={"productionLineGroupJobQueueView"}
			initialsProps={{
				fetchData: ProductionLineGroupJobQueueInitialsApi.getInitialOperations,
				setProductionLineToOperationOnStart: true,
			}}
			inProgressProps={{
				fetchData: ProductionLineGroupJobQueueInProgressApi.getInProgressOperations,
				extraActionBarComponents: (params) => <InProgressViewExtraActionBarComponents {...params} />,
				confirmIfWorkCenterHasIncompleteOperations: true,
			}}
			completedProps={{
				fetchData: ProductionLineGroupJobQueueCompletedApi.getCompletedOperations,
			}}
		/>
	);
};

const InProgressViewExtraActionBarComponents = ({
	selectedOperation,
}: SelectedOperationActionsViewExtraComponentProps) => {
	const openLegacyView = useOpenLegacyView();
	return (
		<>
			<AsyncButton
				icon={faCheck}
				label={i18n.t("operation_receipt")}
				variant={"outlined"}
				onClick={() => {
					openLegacyWorkcenterOperationActionView({
						openLegacyView,
						shopOrderId: selectedOperation.shopOrderId,
						sourceRefData: selectedOperation.sourceRefData,
					});
				}}
			/>
		</>
	);
};
