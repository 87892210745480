import { AavoMosaic } from "../../../../common/mosaic/AavoMosaic.tsx";
import i18n from "i18next";
import { CustomerOrderUndeliveredPackagesDataGrid } from "src/components/views/erp/sales/picking/CustomerOrderUndeliveredPackagesDataGrid.tsx";
import { ReservableCustomerOrderLinesDataGrid } from "./ReservableCustomerOrderLinesDataGrid.tsx";
import React from "react";
import { CustomerOrderPickableLinesDataGrid } from "./CustomerOrderPickableLinesDataGrid.tsx";
import { CustomerOrderPackageView } from "src/api/generated/erp/db/types/tables/customerOrderPackageView.ts";
import { CenteredTypography } from "../../../../common/CenteredTypography.tsx";
import { CustomerOrderPackageLinesDataGrid } from "./CustomerOrderPackageLinesDataGrid.tsx";
import { useRefreshRef } from "src/utils/useRefreshRef.ts";
import { useInterval } from "src/utils/useInterval.ts";

export interface CustomerOrderPickingViewProps {
	customerOrder: {
		customerOrderId: number;
		automaticReservation: boolean;
	};
}

export const CustomerOrderPickingView = ({ customerOrder }: CustomerOrderPickingViewProps) => {
	const [selectedPackage, setSelectedPackage] = React.useState<CustomerOrderPackageView | undefined>(
		undefined,
	);
	const pickableLinesDataGridRefreshRef = useRefreshRef();
	const packageLinesDataGridRefreshRef = useRefreshRef();
	const reservableLinesDataGridRefreshRef = useRefreshRef();
	const undeliveredPackagesDataGridRefreshRef = useRefreshRef();

	useInterval(async () => {
		await Promise.all([
			pickableLinesDataGridRefreshRef.refresh(),
			reservableLinesDataGridRefreshRef.refresh(),
			undeliveredPackagesDataGridRefreshRef.refresh(),
		]);
	}, 60 * 1000);

	return (
		<AavoMosaic
			viewId={"C2FFEDB0721F4F07"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("incomplete_packages"),
					content: (
						<CustomerOrderUndeliveredPackagesDataGrid
							gridId={"C2FB309364222767"}
							customerOrderId={customerOrder.customerOrderId}
							onSelectedPackageChanged={(selectedPackages) =>
								setSelectedPackage(selectedPackages[0])
							}
							pickableLinesDataGridRefreshRef={pickableLinesDataGridRefreshRef}
							selectedRows={selectedPackage == undefined ? [] : [selectedPackage]}
							showCustomerOrderDocumentsButton={true}
							refreshRef={undeliveredPackagesDataGridRefreshRef}
						/>
					),
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("reservable_lines"),
							hidden: customerOrder.automaticReservation,
							content: (
								<ReservableCustomerOrderLinesDataGrid
									customerOrderId={customerOrder.customerOrderId}
									pickableLinesDataGridRefreshRef={pickableLinesDataGridRefreshRef}
									refreshRef={reservableLinesDataGridRefreshRef}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("pickable_lines"),
							content: (
								<CustomerOrderPickableLinesDataGrid
									customerOrderId={customerOrder.customerOrderId}
									customerOrderPackage={selectedPackage}
									refreshRef={pickableLinesDataGridRefreshRef}
									packageLinesDataGridRefreshRef={packageLinesDataGridRefreshRef}
									reservableCustomerOrderLinesDataGridRefreshRef={
										reservableLinesDataGridRefreshRef
									}
									customerOrderAutomaticReservation={customerOrder.automaticReservation}
								/>
							),
						},
						{
							type: "panel",
							title: i18n.t("package_lines"),
							content:
								!selectedPackage ?
									<CenteredTypography>{i18n.t("select_package")}</CenteredTypography>
								:	<CustomerOrderPackageLinesDataGrid
										customerOrderPackage={selectedPackage}
										key={selectedPackage.customerOrderPackageId}
										pickableLinesDataGridRefreshRef={async () => {
											await pickableLinesDataGridRefreshRef.refresh();
										}}
										refreshRef={packageLinesDataGridRefreshRef}
									/>,
						},
					],
				},
			}}
		/>
	);
};
