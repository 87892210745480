import { TransformedConfigurationComponent_Field } from "src/api/generated/erp/configurator/componentTransformation/transformedConfigurationComponent.ts";
import { AavoTextField, AavoTextFieldProps } from "src/components/common/inputFields/AavoTextField.tsx";
import { ConfigurationPropertyValue } from "src/api/generated/io/aavo/applications/db/erp/types/configurationPropertyValue.ts";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import i18n from "i18next";
import { SelectField } from "src/components/common/inputFields/SelectField";
import { toIntOrNull } from "src/utils/strings.tsx";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { ComponentDocumentButton } from "src/components/views/erp/configurator/configuratorForm/componentDocument/ComponentDocumentButton.tsx";
import {
	CONFIGURATION_PROPERTY_NULL_VALUE,
	configurationPropertyValueToString,
	configuratorComponentIndentToMargin,
} from "src/components/views/erp/configurator/configuratorUtils.ts";
import { ConfigurationFieldSelectionOptions_Option } from "src/api/generated/io/aavo/applications/db/erp/types/configurationFieldSelectionOptions.ts";
import { useAsyncState } from "src/utils/async/asyncState.ts";
import { useEffect, useState } from "react";
import { UnmanagedFilesApi } from "src/api/generated/documents/unmanagedFiles/unmanagedFilesApi.ts";
import { logError } from "src/errorHandling/errorLogging.ts";
import { AsyncRender } from "src/components/common/async/AsyncRender.tsx";
import { VerticalBox } from "src/components/common/box/VerticalBox.tsx";
import { RawHtmlContent } from "src/components/common/RawHtmlContent.tsx";
import { AavoFileInput } from "src/components/common/inputFields/fileInput/AavoFileInput";
import { useErrorDialog } from "src/components/common/dialogs/errorDialog/userErrorDialog.ts";
import { uploadFile } from "src/utils/fileUploading.ts";
import { AavoLoading } from "src/components/common/AavoLoading.tsx";
import { faExternalLinkAlt } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { FilePreviewView } from "src/components/views/documents/preview/FilePreviewView";
import { getFileExtension } from "src/utils/fileUtils.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";

export interface ConfiguratorFieldComponentProps {
	field: TransformedConfigurationComponent_Field;
	onChange: (value: ConfigurationPropertyValue) => void;
	onSubmit: (value: ConfigurationPropertyValue) => Promise<unknown>;
}

export const ConfiguratorFieldComponent = (props: ConfiguratorFieldComponentProps) => {
	const { field } = props;

	return (
		<VerticalBox
			sx={{
				marginLeft: configuratorComponentIndentToMargin(field.layout.indent),
			}}
		>
			<HorizontalBox
				sx={{
					gap: 1,
					alignItems: "center",
				}}
			>
				<RenderField {...props} />
				<ComponentDocumentButton componentId={field.configurationComponentId} />
			</HorizontalBox>
			<FieldValueInfoText {...props} />
		</VerticalBox>
	);
};

const RenderField = (props: ConfiguratorFieldComponentProps) => {
	const { field } = props;

	switch (field.fieldType) {
		case "TEXT":
			return <RenderTextField {...props} />;
		case "TEXTAREA":
			return <RenderTextField {...props} multiline minRows={2} />;
		case "INTEGER":
		case "DECIMAL":
			return <RenderTextField {...props} type={"number"} />; // TODO
		case "CHECKBOX":
			return <RenderCheckbox {...props} />;
		case "SELECTION":
			return <RenderSelectField {...props} />;
		case "DOCUMENT":
			return <RenderDocumentField {...props} />;
	}
};

interface RenderTextFieldProps
	extends ConfiguratorFieldComponentProps,
		Pick<AavoTextFieldProps, "multiline" | "minRows" | "type"> {}

const RenderTextField = ({ field, onChange, onSubmit, multiline, minRows, type }: RenderTextFieldProps) => {
	const convertValue = (newValue: string): ConfigurationPropertyValue => {
		switch (field.fieldType) {
			case "INTEGER": {
				const parsedValue = toIntOrNull(newValue);
				return parsedValue == null ?
						CONFIGURATION_PROPERTY_NULL_VALUE
					:	{
							type: "int",
							value: parsedValue,
							label: newValue,
						};
			}
			case "DECIMAL": {
				const parsedValue = parseFloat(newValue);
				return isNaN(parsedValue) ?
						CONFIGURATION_PROPERTY_NULL_VALUE
					:	{
							type: "decimal",
							value: parsedValue,
							label: newValue,
						};
			}
			default:
				return {
					type: "string",
					value: newValue,
					label: newValue,
					fileUuid: null,
				};
		}
	};

	return (
		<AavoTextField
			label={getLabelWithRequiredMark(field)}
			disabled={!field.enabled}
			value={configurationPropertyValueToString(field.currentValue)}
			onChange={(newValue) => {
				onChange(convertValue(newValue));
			}}
			onSubmit={async (newValue) => {
				await onSubmit(convertValue(newValue));
			}}
			error={getErrorMessage(field, field.currentValue)}
			sx={{
				flex: 1,
			}}
			multiline={multiline}
			minRows={minRows}
			type={type}
		/>
	);
};

const RenderCheckbox = ({ field, onSubmit }: ConfiguratorFieldComponentProps) => {
	return (
		<AavoCheckbox
			label={getLabelWithRequiredMark(field)}
			disabled={!field.enabled}
			checked={field.currentValue.type === "bool" ? field.currentValue.value : false}
			onChange={async (newValue) => {
				await onSubmit({
					type: "bool",
					value: newValue,
					label: newValue ? i18n.t("true") : i18n.t("false"),
				});
			}}
		/>
	);
};

const RenderSelectField = (props: ConfiguratorFieldComponentProps) => {
	const { field, onSubmit } = props;

	const [optionImageUrlAsync, setOptionImageUrlAsync, setOptionImageUrlImmediate] = useAsyncState<
		string | null
	>({ defaultValue: null });

	const imageUuid = field.currentValue.type === "string" ? field.currentValue.fileUuid : null;

	const mayHaveImage =
		field.valueInfoTextVisibility === "CONFIGURATOR_AND_PRINTOUT" &&
		field.selectionFieldOptions.some((option) => option.file != null);

	// TODO create some kind of caching mechanism for the image urls.
	useEffect(() => {
		if (imageUuid == null) {
			setOptionImageUrlImmediate(() => null);
		} else {
			setOptionImageUrlImmediate(() => null);
			setOptionImageUrlAsync(() =>
				UnmanagedFilesApi.getUnmanagedFilePresignedDownloadUrl({
					uuid: imageUuid,
				}),
			).catch(logError);
		}
	}, [imageUuid, setOptionImageUrlAsync, setOptionImageUrlImmediate]);

	return (
		<VerticalBox flex={1} gap={1}>
			<SelectField
				label={getLabelWithRequiredMark(field)}
				disabled={!field.enabled}
				value={configurationPropertyValueToString(field.currentValue)}
				options={field.selectionFieldOptions}
				getOptionKey={(option) => option.key}
				getOptionLabel={(option) => option.label}
				disableClearable={field.required}
				sx={{
					flex: 1,
				}}
				error={getErrorMessage(field, field.currentValue)}
				onChange={async (_, option: ConfigurationFieldSelectionOptions_Option | null) => {
					const newConfigurationPropertyValue: ConfigurationPropertyValue =
						option == null ?
							CONFIGURATION_PROPERTY_NULL_VALUE
						:	{
								type: "string",
								value: option.key,
								label: option.label,
								// Keep the current info text for now. Backend will update it
								infoText: field.currentValue.infoText,
								fileUuid: option.file?.fileUuid,
							};
					await onSubmit(newConfigurationPropertyValue);
				}}
			/>
			{mayHaveImage && (
				<AsyncRender
					asyncData={optionImageUrlAsync}
					containerSx={{
						flexDirection: "row",
						alignItems: "flex-start",
						maxWidth: "120px",
						height: "120px",
					}}
					content={(imageUrl) => {
						if (imageUrl === null) return null;
						return (
							<img
								src={imageUrl}
								alt={i18n.t("image")}
								style={{
									objectFit: "contain",
									maxWidth: "100%",
								}}
							/>
						);
					}}
				/>
			)}
		</VerticalBox>
	);
};

const RenderDocumentField = ({ field, onSubmit }: ConfiguratorFieldComponentProps) => {
	const { logErrorAndShowOnDialog } = useErrorDialog();
	const { openDialog } = useGenericDialog();

	const currentValue = field.currentValue.type === "document" ? field.currentValue : null;

	const [file, setFile] = useState<File | null>(
		currentValue == null ? null : new File([], currentValue.fileName),
	);
	const [valueHasChanged, setValueHasChanged] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	return (
		<HorizontalBox gap={1}>
			<AavoFileInput<false>
				multiple={false}
				value={file}
				label={getLabelWithRequiredMark(field)}
				disabled={!field.enabled}
				error={getErrorMessage(field, field.currentValue)}
				placeholder={i18n.t("select_a_file")}
				onChange={onChange}
				hideSizeText={!valueHasChanged} // Hide the size text when "fake File" created from current value
				InputProps={{
					endAdornment: isLoading ? <AavoLoading size={"xl"} /> : undefined,
				}}
			/>
			{currentValue != null && (
				<AsyncButton icon={faExternalLinkAlt} tooltip={i18n.t("open")} onClick={openSelectedFile} />
			)}
		</HorizontalBox>
	);

	async function onChange(newFile: File | null) {
		try {
			setFile(newFile);
			setValueHasChanged(true);
			if (newFile == null) {
				await onSubmit(CONFIGURATION_PROPERTY_NULL_VALUE);
				return;
			}
			setIsLoading(true);
			const fileHandle = await uploadFile(newFile);
			const fileUuid = await UnmanagedFilesApi.createUnmanagedFile({ fileHandle });
			await onSubmit({
				type: "document",
				value: fileUuid,
				documentCategoryId: field.documentFieldDocumentCategoryId!,
				documentDescription: field.documentFieldDocumentDescription,
				label: field.documentFieldDocumentDescription,
				fileName: newFile.name,
			});
		} catch (e) {
			logErrorAndShowOnDialog(e);
		} finally {
			setIsLoading(false);
		}
	}

	async function openSelectedFile() {
		const { value: fileUuid, fileName } = currentValue!;
		const fileUrl = await UnmanagedFilesApi.getUnmanagedFilePresignedDownloadUrl({
			uuid: fileUuid,
		});
		openDialog({
			size: "fullscreen",
			title: fileName,
			content: <FilePreviewView fileUrl={fileUrl} extension={getFileExtension(fileName)!} />,
		});
	}
};

const FieldValueInfoText = ({ field }: ConfiguratorFieldComponentProps) => {
	const infoText = field.currentValue.infoText;
	if (infoText == null || infoText.trim() == "") return null;
	if (field.valueInfoTextVisibility === "PRINTOUT") return null;

	return (
		<RawHtmlContent
			sx={{
				marginTop: "0.5rem",
			}}
			html={infoText}
		/>
	);
};

function getLabelWithRequiredMark(field: TransformedConfigurationComponent_Field): string {
	return field.label + (field.required ? " *" : "");
}

function getErrorMessage(
	field: TransformedConfigurationComponent_Field,
	value: ConfigurationPropertyValue,
): string | undefined {
	return field.required && (value.type === "null" || value.value === "") ? i18n.t("required") : undefined;
}
