import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import {
	getShopOrderBatchStateLabel,
	getShopOrderBatchStateLabels,
	ShopOrderBatchState,
	ShopOrderBatchStateValues,
} from "src/api/generated/erp/db/types/enums/shopOrderBatchState.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	floatColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { faBan, faPrint, faShare, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { ShopOrderBatchActionApi } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchActionApi.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { downloadFile } from "src/utils/fileDownloading.ts";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { ShopOrderBatchForm } from "src/components/views/erp/production/shopOrderBatch/ShopOrderBatchForm.tsx";
import { ShopOrderBatchEditApi } from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchEditApi.ts";
import { RefreshableElementRef } from "src/utils/useRefreshRef.ts";
import {
	ShopOrderBatchDataGridApi,
	ShopOrderBatchDataGridApi_ShopOrderBatchDto,
} from "src/api/generated/erp/production/shopOrderBatch/api/shopOrderBatchDataGridApi.ts";
import React from "react";

export interface ShopOrderBatchesDataGridProps {
	onlyShopOrderBatchId?: number;
	onSelectionChanged?: (shopOrderBatch: ShopOrderBatchDataGridApi_ShopOrderBatchDto | null) => void;
	refreshRef?: RefreshableElementRef;
}

export const ShopOrderBatchesDataGrid = ({
	onlyShopOrderBatchId,
	onSelectionChanged,
	refreshRef,
}: ShopOrderBatchesDataGridProps) => {
	const showConfirmation = useConfirmDialog();
	const tenantCustomizations = useTenantCustomizations();

	const tenantCustomContextMenuComponents = tenantCustomizations.erp?.production?.shopOrderBatchContextMenuComponents;

	return (
		<ServerSideDataGridModel
			gridId={"12BFE501862a3D7C"}
			fetchData={(params) => ShopOrderBatchDataGridApi.getShopOrderBatches({ onlyShopOrderBatchId, ...params })}
			getDataModelResult={(data) => data}
			refreshRef={refreshRef}
			getRowId={(row) => row.shopOrderBatchId}
			onSelectionChanged={(rows) => {
				onSelectionChanged?.(rows?.[0] ?? null);
			}}
			selectFirstRowOnLoad={onlyShopOrderBatchId != null}
			initialParams={{
				searchQuery: "",
				batchState: genericNullableValue<ShopOrderBatchState>(null),
				includeReleased: false,
				includeClosed: false,
				onlyDefaultSite: true,
			}}
			render={({ currentParams, dataGridProps, onlySelectedRow, refreshData }) => {
				return (
					<CrudDataGrid<ShopOrderBatchDataGridApi_ShopOrderBatchDto>
						columns={[
							integerColumn({
								field: "shopOrderBatchId",
								headerName: i18n.t("number_short"),
								width: 70,
							}),
							textColumn({
								field: "siteName",
								headerName: i18n.t("site"),
								width: 90,
							}),
							textColumn({
								field: "batchName",
								headerName: i18n.t("name"),
							}),
							enumColumn({
								field: "batchState",
								headerName: i18n.t("state"),
								enumLabels: getShopOrderBatchStateLabels(),
							}),
							textColumn({
								field: "productionLineName",
								headerName: i18n.t("production_line"),
							}),
							floatColumn({
								field: "totalQuantity",
								headerName: i18n.t("quantity"),
							}),
							floatColumn({
								field: "totalCapacityQuantity",
								headerName: i18n.t("capacity_quantity"),
								width: 140,
							}),
							dateTimeColumn({
								field: "createdAt",
								headerName: i18n.t("created_at"),
								width: 130,
							}),
							textColumn({
								field: "createdByUserName",
								headerName: i18n.t("created_by"),
								width: 130,
							}),
							dateTimeColumn({
								field: "releasedAt",
								headerName: i18n.t("released"),
								width: 130,
							}),
							textColumn({
								field: "releasedByUserName",
								headerName: i18n.t("released_by"),
								width: 130,
							}),
							dateColumn({
								field: "earliestDemandDate",
								headerName: i18n.t("earliest_demand_date"),
								width: 130,
							}),
							dateColumn({
								field: "plannedBeginDate",
								headerName: i18n.t("planned_begin_date"),
								width: 130,
							}),
						]}
						actionBarComponents={
							<>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={async (value) => {
										await refreshData({ searchQuery: value });
									}}
								/>
								<SelectField
									label={i18n.t("state")}
									options={ShopOrderBatchStateValues}
									getOptionKey={(option) => option}
									getOptionLabel={(option) => getShopOrderBatchStateLabel(option)}
									onChange={async (value) => {
										await refreshData({ batchState: value });
									}}
								/>
							</>
						}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"showReleased"}
								label={i18n.t("show_released")}
								checked={currentParams.includeReleased}
								onChange={async (checked) => {
									await refreshData({ includeReleased: checked });
								}}
							/>,
							<MenuCheckbox
								key={"showOnlyDefaultSite"}
								label={i18n.t("only_default_site")}
								checked={currentParams.onlyDefaultSite}
								onChange={async (checked) => {
									await refreshData({ onlyDefaultSite: checked });
								}}
							/>,
						]}
						rowContextMenuComponents={({ row }) => [
							<AsyncMenuButton
								key={"printWorkOrder"}
								icon={faPrint}
								label={i18n.t("work_order")}
								onClick={async () => {
									const fileHandle = await ShopOrderBatchActionApi.printShopOrderBatchWorkOrder({
										shopOrderBatchId: row.shopOrderBatchId,
									});
									downloadFile(fileHandle);
								}}
							/>,
							row.batchState === "PLANNED" && (
								<AsyncMenuButton
									key={"release"}
									label={i18n.t("release")}
									icon={faShare}
									onClick={async () => {
										const confirmed = await showConfirmation({
											title: i18n.t("are_you_sure"),
											message: i18n.t("do_you_want_release_this_shop_order_batch"),
										});
										if (!confirmed) return;

										await ShopOrderBatchActionApi.releaseShopOrderBatch({
											shopOrderBatchId: row.shopOrderBatchId,
										});
										await refreshData();
									}}
								/>
							),
							row.batchState === "PLANNED" && (
								<AsyncMenuButton
									key={"revertToInitial"}
									icon={faUndo}
									label={i18n.t("revert_to_initial")}
									onClick={async () => {
										await ShopOrderBatchActionApi.revertBatchStateToInitial({
											shopOrderBatchId: row.shopOrderBatchId,
										});
										await refreshData();
									}}
								/>
							),
							row.batchState === "RELEASED" && (
								<AsyncMenuButton
									key={"revertToPlanned"}
									icon={faUndo}
									label={i18n.t("revert_to_planned")}
									onClick={async () => {
										await ShopOrderBatchActionApi.revertBatchStateToPlanned({
											shopOrderBatchId: row.shopOrderBatchId,
										});
										await refreshData();
									}}
								/>
							),
							<AsyncMenuButton
								key={"cancel"}
								icon={faBan}
								label={i18n.t("cancel")}
								onClick={async () => {
									await ShopOrderBatchActionApi.cancelBatch({
										shopOrderBatchId: row.shopOrderBatchId,
									});
									await refreshData();
								}}
							/>,
							tenantCustomContextMenuComponents &&
								React.createElement(tenantCustomContextMenuComponents, { shopOrderBatch: row }),
						]}
						form={{
							editEnabled: true,
							addRowEnabled: true,
							dialogSize: "sm",
							dialogTitle: i18n.t("shop_order_batch"),
							component: ({ row, onCompleted, onFormEdited }) => (
								<ShopOrderBatchForm
									shopOrderBatchId={row?.shopOrderBatchId}
									onCompleted={onCompleted}
									onFormEdited={onFormEdited}
								/>
							),
						}}
						remove={{
							type: onlySelectedRow == undefined ? "disabled" : "enabled",
							action: async () => {
								await ShopOrderBatchEditApi.delete_({
									shopOrderBatchId: onlySelectedRow!.shopOrderBatchId,
								});
							},
						}}
						disableMultipleRowSelection={true}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
