import { faQuestion } from "@fortawesome/pro-regular-svg-icons";
import { OpenSurveyApi } from "src/api/generated/surveys/api/openSurveyApi";
import i18n from "i18next";
import { useHandleLegacyCommandActionRequests } from "src/components/views/legacy/useHandleLegacyCommandActionRequests.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton";
import { useTenantCustomizations } from "src/tenantCustomizations/useTenantCustomizations.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { SurveySubmissionForm } from "src/components/views/erp/surveys/submissions/SurveySubmissionForm.tsx";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { ShopOrderOperationView } from "src/api/generated/erp/db/types/tables/shopOrderOperationView.ts";
import { ObjectSurveySubmissionApi } from "src/api/generated/erp/surveys/api/objectSurveySubmissionApi.ts";

export interface OpenShopOrderOperationSurveyButtonProps {
	shopOrderOperation: Pick<
		ShopOrderOperationView,
		"shopOrderOperationId" | "surveyFormId" | "surveyFormName" | "surveyConfigurationId" | "sourceRefData"
	>;
	onSubmit?: () => Promise<unknown>;
}

export const OpenShopOrderOperationSurveyButton = ({
	shopOrderOperation: { shopOrderOperationId, surveyFormId, surveyFormName, surveyConfigurationId, sourceRefData },
	onSubmit,
}: OpenShopOrderOperationSurveyButtonProps) => {
	const { tenantConfig } = useTenantCustomizations();
	const { openDialog } = useGenericDialog();

	const handleLegacyCommandActionRequests = useHandleLegacyCommandActionRequests();

	const legacySurveysEnabled = tenantConfig.erp.legacySurveysEnabled;
	const surveysEnabled = tenantConfig.erp.surveysEnabled;

	if (!surveysEnabled && !legacySurveysEnabled) {
		return null;
	}

	if (surveysEnabled) {
		if (surveyFormId == null) {
			return null;
		}
	} else if (legacySurveysEnabled && surveyConfigurationId == null) {
		return null;
	}

	return (
		<AsyncButton
			label={i18n.t("survey")}
			icon={faQuestion}
			variant={"outlined"}
			onClick={async () => {
				if (surveysEnabled && surveyFormId != null) {
					await openSurveyForm(surveyFormId);
				} else if (legacySurveysEnabled && surveyConfigurationId != null) {
					await openLegacySurveyForm(surveyConfigurationId);
				}
			}}
		/>
	);

	async function openSurveyForm(surveyFormId: number) {
		const existingSubmission = await ObjectSurveySubmissionApi.getObjectSurveySubmission({
			objectType: "SHOP_ORDER_OPERATION",
			objectId: shopOrderOperationId,
		});
		openFormOnDialog({
			openDialog,
			title: surveyFormName,
			size: "lg",
			component: SurveySubmissionForm,
			props: {
				surveyFormId,
				surveySubmissionId: existingSubmission?.surveySubmissionId,
				objectRef: {
					objectType: "SHOP_ORDER_OPERATION",
					objectId: shopOrderOperationId,
					objectDescription: sourceRefData ?? "",
				},
			},
			onSubmit: onSubmit,
		});
	}

	async function openLegacySurveyForm(surveyConfigurationId: number) {
		const actionRequests = await OpenSurveyApi.getOpenSurveyActionRequests({
			surveyConfigurationId,
			sourceRef: {
				objectType: "SHOP_ORDER_OPERATION",
				objectId: shopOrderOperationId,
			},
			surveyInfo: sourceRefData ?? "",
		});
		handleLegacyCommandActionRequests(actionRequests);
	}
};
