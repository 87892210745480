import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import {
	faBell,
	faBellSlash,
	faBox,
	faChalkboardTeacher,
	faCheckDouble,
	faDolly,
	faPeopleCarry,
	faRefresh,
	faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { AddDocumentsToObjectButton } from "src/components/views/documents/AddDocumentsToObjectButton.tsx";
import i18n from "i18next";
import { ShopOrderOperationStateTransferApi } from "src/api/generated/erp/production/api/jobQueues/shopOrderOperationStateTransferApi.ts";
import { OpenShopOrderOperationSurveyButton } from "src/components/views/surveys/OpenShopOrderOperationSurveyButton.tsx";
import {
	openLegacyIncompleteOperationUserWorkcenterReportingForm,
	openLegacyWorkCenterWarehouseSupplyRequestContainerView,
} from "src/components/views/legacy/legacyViewAdapters.ts";
import { WorkCenterJobQueueInProgressApi } from "src/api/generated/erp/production/api/jobQueues/workCenter/workCenterJobQueueInProgressApi.ts";
import { Workcenter } from "src/api/generated/erp/db/types/tables/workcenter.ts";
import { JobQueueShopOrderOperation } from "src/components/views/erp/production/jobQueue/base/JobQueueShopOrderOperation.ts";
import { useJobQueueViewContext } from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";
import { OperationAndWorkCenterDocumentsView } from "src/components/views/erp/production/jobQueue/base/inProgressView/OperationAndWorkCenterDocumentsView.tsx";
import { ReactNode } from "react";
import { ProductionLineJobQueueInProgressApi_WorkCenterControlChartDto } from "src/api/generated/erp/production/api/jobQueues/productionLine/productionLineJobQueueInProgressApi.ts";
import { ShopOrderBomReserveAndPickView } from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomReserveAndPickView.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";

export interface SelectedOperationActionsViewProps {
	selectedOperation: JobQueueShopOrderOperation | undefined;
	workCenter: Workcenter;
	workCenterControlChart: ProductionLineJobQueueInProgressApi_WorkCenterControlChartDto | null | undefined;
	extraActionBarComponents?: (props: SelectedOperationActionsViewExtraComponentProps) => ReactNode;
	alwaysAllowOperationCompletion?: boolean;
	confirmIfWorkCenterHasIncompleteOperations?: boolean;
}

export interface SelectedOperationActionsViewExtraComponentProps
	extends Pick<SelectedOperationActionsViewProps, "workCenterControlChart" | "workCenter"> {
	selectedOperation: JobQueueShopOrderOperation;
}

export const SelectedOperationActionsView = ({
	selectedOperation,
	workCenter,
	extraActionBarComponents,
	workCenterControlChart,
	alwaysAllowOperationCompletion,
	confirmIfWorkCenterHasIncompleteOperations,
}: SelectedOperationActionsViewProps) => {
	const {
		initialsViewRefreshRef,
		inProgressViewRefreshRef,
		completedViewRefreshRef,
		inProgressViewDocumentsRefreshRef,
	} = useJobQueueViewContext();

	const openLegacyView = useOpenLegacyView();
	const { openDialog } = useGenericDialog();
	const showConfirmDialog = useConfirmDialog();

	if (selectedOperation == null) return null;

	return (
		<HorizontalBox
			sx={{
				padding: 1,
				gap: 1,
				flexWrap: "wrap",
			}}
		>
			<AsyncButton
				icon={faRefresh}
				onClick={async () => {
					await inProgressViewRefreshRef.refresh();
				}}
			/>
			<AddDocumentsToObjectButton
				objectRef={{
					objectType: "SHOP_ORDER_OPERATION",
					objectId: selectedOperation.shopOrderOperationId,
				}}
				afterUpload={inProgressViewDocumentsRefreshRef.refresh}
			/>
			<AsyncButton
				icon={faChalkboardTeacher}
				onClick={() => {
					openDialog({
						title: i18n.t("documents"),
						content: (
							<OperationAndWorkCenterDocumentsView
								operationId={selectedOperation.operationId}
								workCenterId={workCenter.workcenterId}
							/>
						),
					});
				}}
			/>
			{extraActionBarComponents?.({
				selectedOperation: selectedOperation,
				workCenter: workCenter,
				workCenterControlChart: workCenterControlChart,
			})}
			{(alwaysAllowOperationCompletion || workCenter.completionEnabled) && (
				<AsyncButton
					label={i18n.t("complete")}
					icon={faCheckDouble}
					variant={"outlined"}
					onClick={async () => {
						if (confirmIfWorkCenterHasIncompleteOperations) {
							const workcenterHasIncompleteOperations =
								await ShopOrderOperationStateTransferApi.hasWorkcenterIncompleteOperations({
									shopOrderId: selectedOperation.shopOrderId,
								});
							if (workcenterHasIncompleteOperations) {
								const confirmed = await showConfirmDialog({
									title: i18n.t("are_you_sure"),
									message: i18n.t("workcenter_has_incomplete_operations"),
								});
								if (!confirmed) return;
							}
						}

						await ShopOrderOperationStateTransferApi.completeOperations({
							shopOrderOperationIds: [selectedOperation.shopOrderOperationId],
						});
						await Promise.all([inProgressViewRefreshRef.refresh(), completedViewRefreshRef.refresh()]);
					}}
				/>
			)}
			<AsyncButton
				label={i18n.t("cancel")}
				icon={faUndo}
				variant={"outlined"}
				onClick={async () => {
					await ShopOrderOperationStateTransferApi.revertStartOperations({
						shopOrderOperationIds: [selectedOperation.shopOrderOperationId],
					});
					await Promise.all([inProgressViewRefreshRef.refresh(), initialsViewRefreshRef.refresh()]);
				}}
			/>
			<OpenShopOrderOperationSurveyButton shopOrderOperation={selectedOperation} />
			<AsyncButton
				icon={faDolly}
				label={i18n.t("reserve_and_pick")}
				variant={"outlined"}
				onClick={() => {
					openDialog(() => ({
						title: i18n.t("reserve_and_pick_materials"),
						size: "lg",
						content: (
							<ShopOrderBomReserveAndPickView
								shopOrderId={selectedOperation.shopOrderId}
								shopOrderOperationId={selectedOperation.shopOrderOperationId}
							/>
						),
					}));
				}}
			/>
			<AsyncButton
				label={i18n.t("warehouse_transfer")}
				icon={faPeopleCarry}
				variant={"outlined"}
				onClick={() => {
					openLegacyWorkCenterWarehouseSupplyRequestContainerView({
						openLegacyView,
					});
				}}
			/>
			{workCenter.partialCompletionEnabled && (
				<AsyncButton
					label={i18n.t("report")}
					icon={faBox}
					variant={"outlined"}
					onClick={async () => {
						openLegacyIncompleteOperationUserWorkcenterReportingForm({
							openLegacyView,
							shopOrderId: selectedOperation.shopOrderId,
							shopOrderOperationId: selectedOperation.shopOrderOperationId,
							refreshSource: async () => {
								await Promise.all([
									inProgressViewRefreshRef.refresh(),
									completedViewRefreshRef.refresh(),
								]);
							},
						});
					}}
				/>
			)}
			{workCenter.andonEnabled && (
				<AsyncButton
					label={workCenter.andonActive ? i18n.t("clear_andon") : i18n.t("andon")}
					icon={workCenter.andonActive ? faBellSlash : faBell}
					variant={"outlined"}
					onClick={async () => {
						await WorkCenterJobQueueInProgressApi.setWorkCenterHasAndon({
							workcenterId: workCenter.workcenterId,
							hasAndon: !workCenter.andonActive,
						});
						await inProgressViewRefreshRef.refresh();
					}}
				/>
			)}
		</HorizontalBox>
	);
};
