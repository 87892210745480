import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import {
	SurveyAuditApi,
	SurveyAuditApi_SurveySubmissionToAuditDto,
} from "src/api/generated/erp/surveys/api/surveyAuditApi.ts";
import { getSurveySubmissionDataGridColumns } from "src/components/views/erp/surveys/surveyUtils.ts";
import { AavoDataGrid } from "src/components/common/dataGrid/AavoDataGrid.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { faCheck, faCheckDouble, faExternalLink, faUndo } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import { SurveyCategoryApi } from "src/api/generated/erp/surveys/api/surveyCategoryApi.ts";
import { LazySelectField } from "src/components/common/inputFields/LazySelectField.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { openFormOnDialog } from "src/components/common/dialogs/formDialog/openFormOnDialog.ts";
import { SurveySubmissionForm } from "src/components/views/erp/surveys/submissions/SurveySubmissionForm.tsx";
import { SurveySubmissionView } from "src/api/generated/erp/db/types/tables/surveySubmissionView.ts";

export const SurveySubmissionsToAuditDataGrid = () => {
	const { openDialog } = useGenericDialog();
	const showConfirmDialog = useConfirmDialog();

	return (
		<ServerSideDataGridModel
			fetchData={(params) => SurveyAuditApi.getSurveySubmissionsToAudit(params)}
			initialParams={{
				includeAudited: false,
				surveyCategoryId: genericNullableValue<number>(),
			}}
			getDataModelResult={(response) => response}
			getRowId={(row) => row.surveySubmissionId}
			gridId={"F9C04491284C8C34"}
			selectFirstRowOnLoad
			render={({ dataGridProps, onlySelectedRow, refreshData, currentParams }) => (
				<AavoDataGrid<SurveyAuditApi_SurveySubmissionToAuditDto>
					columns={getSurveySubmissionDataGridColumns({})}
					onRowDoubleClick={({ row }) => openSurveySubmissionForm(row)}
					actionBarComponents={
						<>
							<LazySelectField
								label={i18n.t("category")}
								fetchOptions={() => SurveyCategoryApi.getSurveyCategories()}
								getOptionKey={(option) => option.surveyCategoryId}
								getOptionLabel={(option) => option.name}
								defaultValue={currentParams.surveyCategoryId}
								onChange={(surveyCategoryId) => refreshData({ surveyCategoryId })}
							/>
							<AsyncButton
								label={i18n.t("open")}
								icon={faExternalLink}
								disabled={!onlySelectedRow}
								variant={"outlined"}
								onClick={() => openSurveySubmissionForm(onlySelectedRow!)}
							/>
							{onlySelectedRow &&
								onlySelectedRow.checkingAllowed &&
								onlySelectedRow.state === "SUBMITTED" && (
									<AsyncButton
										label={i18n.t("check")}
										icon={faCheck}
										variant={"outlined"}
										onClick={async () => {
											const confirmed = await showConfirmDialog();
											if (!confirmed) return;

											await SurveyAuditApi.checkSurveySubmission({
												surveySubmissionId: onlySelectedRow.surveySubmissionId,
											});
											await refreshData();
										}}
									/>
								)}
							{onlySelectedRow &&
								onlySelectedRow.checkingAllowed &&
								onlySelectedRow.state === "CHECKED" && (
									<AsyncButton
										label={i18n.t("revert_check")}
										icon={faUndo}
										variant={"outlined"}
										onClick={async () => {
											const confirmed = await showConfirmDialog();
											if (!confirmed) return;

											await SurveyAuditApi.revertCheckSurveySubmission({
												surveySubmissionId: onlySelectedRow.surveySubmissionId,
											});
											await refreshData();
										}}
									/>
								)}
							{onlySelectedRow &&
								onlySelectedRow.approvingAllowed &&
								onlySelectedRow.state !== "APPROVED" && (
									<AsyncButton
										label={i18n.t("approve")}
										icon={faCheckDouble}
										variant={"outlined"}
										onClick={async () => {
											const confirmed = await showConfirmDialog();
											if (!confirmed) return;

											await SurveyAuditApi.approveSurveySubmission({
												surveySubmissionId: onlySelectedRow.surveySubmissionId,
											});
											await refreshData();
										}}
									/>
								)}
							{onlySelectedRow &&
								onlySelectedRow.approvingAllowed &&
								onlySelectedRow.state === "APPROVED" && (
									<AsyncButton
										label={i18n.t("revert_approve")}
										icon={faUndo}
										variant={"outlined"}
										onClick={async () => {
											const confirmed = await showConfirmDialog();
											if (!confirmed) return;

											await SurveyAuditApi.revertApproveSurveySubmission({
												surveySubmissionId: onlySelectedRow.surveySubmissionId,
											});
											await refreshData();
										}}
									/>
								)}
						</>
					}
					actionBarMenuComponents={[
						<MenuCheckbox
							key={"showAll"}
							label={i18n.t("show_all")}
							checked={currentParams.includeAudited}
							onChange={(includeAudited) => refreshData({ includeAudited })}
						/>,
					]}
					{...dataGridProps}
				/>
			)}
		/>
	);

	function openSurveySubmissionForm(surveySubmission: SurveySubmissionView) {
		openFormOnDialog({
			openDialog,
			component: SurveySubmissionForm,
			size: "lg",
			title: surveySubmission.surveyFormName,
			props: {
				surveyFormId: surveySubmission.surveyFormId,
				surveySubmissionId: surveySubmission.surveySubmissionId,
				readOnly: true,
			},
		});
	}
};
