import { ClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel";
import {
	CatalogPartRevisionAttributeRulesApi,
	CatalogPartRevisionAttributeRulesApi_AttributeRuleDto,
} from "src/api/generated/erp/configurator/management/catalogPart/api/catalogPartRevisionAttributeRulesApi.ts";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid";
import { textColumn } from "src/components/common/dataGrid/columns";
import i18n from "i18next";
import { CatalogPartRevisionAttributeRuleForm } from "src/components/views/erp/configurator/managing/catalogPart/attributeRules/CatalogPartRevisionAttributeRuleForm.tsx";
import { CatalogPartRevision } from "src/api/generated/erp/db/types/tables/catalogPartRevision.ts";

export interface CatalogPartRevisionAttributeRulesDataGridProps {
	catalogPartRevision: CatalogPartRevision;
	onSelectionChanged: (selectedRow: CatalogPartRevisionAttributeRulesApi_AttributeRuleDto | undefined) => void;
}

export const CatalogPartRevisionAttributeRulesDataGrid = ({
	catalogPartRevision,
	onSelectionChanged,
}: CatalogPartRevisionAttributeRulesDataGridProps) => {
	return (
		<ClientSideDataGridModel
			fetchData={() =>
				CatalogPartRevisionAttributeRulesApi.getCatalogPartRevisionAttributeRules({
					catalogPartRevisionId: catalogPartRevision.catalogPartRevisionId,
				})
			}
			initialParams={{}}
			getRows={(data) => data}
			getRowId={(row) => row.catalogPartRevisionAttributeConfigurationRuleId}
			onSelectionChanged={(rows) => onSelectionChanged(rows[0])}
			gridId={"FC2F91A4F2C090C1"}
			render={({ dataGridProps }) => (
				<CrudDataGrid<CatalogPartRevisionAttributeRulesApi_AttributeRuleDto>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "attributeFieldLabel",
							headerName: i18n.t("attribute"),
							width: 250,
						}),
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogSize: "xl",
						dialogTitle: i18n.t("attribute_rule"),
						component: ({ row, onCompleted, onFormEdited }) => (
							<CatalogPartRevisionAttributeRuleForm
								catalogPartRevision={catalogPartRevision}
								ruleId={row?.catalogPartRevisionAttributeConfigurationRuleId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) =>
						CatalogPartRevisionAttributeRulesApi.delete_({
							ruleId: row.catalogPartRevisionAttributeConfigurationRuleId,
						})
					}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
