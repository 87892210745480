import {ClientSideDataGridModel} from "src/components/common/dataGrid/gridModel/ClientSideDataGridModel.tsx";
import {
    ShopOrderBomDataGridApi,
    ShopOrderBomDataGridApi_ShopOrderLineData,
} from "src/api/generated/erp/production/api/shopOrderBom/shopOrderBomDataGridApi.ts";
import {CrudDataGrid} from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import {
    dateTimeColumn,
    enumColumn,
    floatColumn,
    integerColumn,
    textColumn,
} from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import {concatWithPipe} from "src/utils/strings.tsx";
import {getAcquisitionMethodLabel} from "src/api/generated/erp/db/types/enums/acquisitionMethod.ts";
import {getShopOrderLineStateLabels} from "src/api/generated/erp/db/types/enums/shopOrderLineState.ts";
import {DocumentsOfObjectButton} from "src/components/views/documents/objectDocuments/DocumentsOfObjectButton.tsx";
import {nullableAavoObjectRef} from "src/utils/aavoObjectRefUtils.ts";
import {AsyncMenuButton} from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import {
    faBan,
    faDolly,
    faFileUpload,
    faShare,
    faSitemap,
    faTable,
    faTools,
    faUndo,
} from "@fortawesome/pro-regular-svg-icons";
import { useFormInput } from "src/components/common/dialogs/formInput/useFormInput.tsx";
import { FormSingleFileInputField } from "src/components/common/forms/fields/FormSingleFileInputField.tsx";
import { ShopOrderBomActionApi } from "src/api/generated/erp/production/api/shopOrderBom/shopOrderBomActionApi.ts";
import { uploadFile } from "src/utils/fileUploading.ts";
import { TypographyWithLineBreaks } from "src/components/common/typography/TypographyWithLineBreaks.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { OpenPurchaseOrderButton } from "src/components/views/erp/utilComponents/OpenPurchaseOrderButton.tsx";
import { OpenSitePartButton } from "src/components/views/erp/utilComponents/OpenSitePartButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ShopOrderLineConfiguratorView } from "src/components/views/erp/configurator/configuratorForm/impl/ShopOrderLineConfiguratorView.tsx";
import { PartConfigurationInspectingView } from "src/components/views/erp/configurator/inspecting/PartConfigurationInspectingView.tsx";
import { ShopOrderLineForm } from "src/components/views/erp/production/shopOrderBom/ShopOrderLineForm.tsx";
import { ShopOrderBomTreeContainer } from "src/components/views/erp/production/shopOrderBom/ShopOrderBomTreeContainer.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { OpenShopOrderButton } from "src/components/views/erp/utilComponents/OpenShopOrderButton.tsx";
import { AavoCheckbox } from "src/components/common/inputFields/AavoCheckbox.tsx";
import { ShopOrderBomReserveAndPickView } from "src/components/views/erp/production/ShopOrderPicking/ShopOrderBomReserveAndPickView.tsx";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import {cancelShopOrderBomLine} from "src/components/views/erp/production/shopOrderBom/cancelShopOrderBomLine.ts";

export interface ShopOrderBomDataGridProps {
	shopOrderId: number;
	editingEnabled?: boolean;
	filterByShopOrderOperationId?: number | null;
}

export const ShopOrderBomDataGrid = ({
	shopOrderId,
	editingEnabled = true,
	filterByShopOrderOperationId,
}: ShopOrderBomDataGridProps) => {
	const showInputForm = useFormInput();
	const showConfirmDialog = useConfirmDialog();
	const { openDialog } = useGenericDialog();

	return (
		<ClientSideDataGridModel
			gridId={"F600FC6943D43A77"}
			fetchData={async ({ searchQuery, showFullBom, showCancelledLines }) =>
				await ShopOrderBomDataGridApi.getGridData({
					searchQuery: searchQuery,
					shopOrderId: shopOrderId,
					includeCancelledLines: showCancelledLines,
					filterByShopOrderOperationId: showFullBom ? null : filterByShopOrderOperationId,
				})
			}
			initialParams={{
				searchQuery: "",
				showFullBom: false,
				showCancelledLines: false,
			}}
			storedParams={["showFullBom"]}
			getRows={(data) => data.lines}
			getRowId={(row) => row.shopOrderLineId}
			render={({ data, dataGridProps, onlySelectedRow, refreshData, currentParams }) => {
				const shopOrderIsClosed =
					data.shopOrderState === "READY" || data.shopOrderState === "CANCELLED";

				const shopOrderIsAtLeastStarted = shopOrderIsClosed || data.shopOrderState === "STARTED";

				return (
					<CrudDataGrid<ShopOrderBomDataGridApi_ShopOrderLineData>
						columns={[
							integerColumn({
								field: "referenceNo",
								headerName: i18n.t("reference_no"),
								width: 70,
							}),
							textColumn({
								field: "configurationOrPartDescription",
								headerName: i18n.t("part"),
								width: 200,
							}),
							floatColumn({
								field: "quantity",
								headerName: i18n.t("quantity"),
								width: 70,
							}),
							textColumn({
								field: "partUnit",
								headerName: i18n.t("unit"),
								width: 70,
							}),
							textColumn({
								field: "operationId",
								headerName: i18n.t("operation"),
								valueGetter: (_, row) =>
									concatWithPipe(row.operationNo, row.operationDescription),
								width: 150,
							}),
							textColumn({
								field: "acquisitionMethod",
								headerName: i18n.t("acquisition_method"),
								valueGetter: (_, row) => {
									const methodLabel = getAcquisitionMethodLabel(row.acquisitionMethod);
									return (
										row.acquisitionShopOrderId != null ?
											`${methodLabel} (${row.acquisitionShopOrderId})`
										: row.acquisitionPurchaseOrderId != null ?
											`${methodLabel} (${row.acquisitionPurchaseOrderId})`
										:	`${methodLabel}`
									);
								},
							}),
							textColumn({
								field: "note",
								headerName: i18n.t("note"),
							}),
							floatColumn({
								field: "reservedQuantity",
								headerName: i18n.t("reserved_quantity"),
							}),
							floatColumn({
								field: "pickedQuantity",
								headerName: i18n.t("picked_quantity"),
								width: 120,
							}),
							enumColumn({
								field: "shopOrderLineState",
								headerName: i18n.t("state"),
								enumLabels: getShopOrderLineStateLabels(),
							}),
							dateTimeColumn({
								field: "releasedAt",
								headerName: i18n.t("released_at"),
							}),
							dateTimeColumn({
								field: "cancelledDate",
								headerName: i18n.t("cancelled_date"),
							}),
							integerColumn({
								field: "shopOrderLineId",
								headerName: i18n.t("id"),
							}),
						]}
						actionBarComponents={
							<>
								<DocumentsOfObjectButton
									objectRef={nullableAavoObjectRef(
										"SHOP_ORDER_LINE",
										onlySelectedRow?.shopOrderLineId,
									)}
								/>
								<AavoTextField
									label={i18n.t("search")}
									onSubmit={(value) => refreshData({ searchQuery: value })}
								/>
								<AsyncButton
									key={"reserveAndPick"}
									icon={faDolly}
									label={i18n.t("reserve_and_pick")}
									variant={"outlined"}
									onClick={() => {
										openDialog(() => ({
											title: i18n.t("reserve_and_pick_materials"),
											size: "lg",
											content: (
												<ShopOrderBomReserveAndPickView shopOrderId={shopOrderId} />
											),
										}));
									}}
								/>
								<AsyncButton
									label={i18n.t("tree_view")}
									icon={faSitemap}
									variant={"outlined"}
									onClick={() => {
										openDialog(() => ({
											title: i18n.t("tree_view"),
											content: <ShopOrderBomTreeContainer shopOrderId={shopOrderId} />,
										}));
									}}
								/>
								{filterByShopOrderOperationId != null && (
									<AavoCheckbox
										label={i18n.t("full_bom")}
										checked={currentParams.showFullBom}
										onChange={async (value) => await refreshData({ showFullBom: value })}
									/>
								)}
							</>
						}
						actionBarMenuComponents={[
							<MenuCheckbox
								key={"showCancelled"}
								label={i18n.t("show_cancelled")}
								checked={currentParams.showCancelledLines}
								onChange={(value) => refreshData({ showCancelledLines: value })}
							/>,
							editingEnabled && (
								<AsyncMenuButton
									key={"import"}
									label={i18n.t("import_bom")}
									icon={faFileUpload}
									onClick={async () => {
										const formInput = await showInputForm<{ file: File }>({
											title: i18n.t("import_bom"),
											content: ({ control }) => (
												<>
													<FormSingleFileInputField
														control={control}
														name={"file"}
														label={i18n.t("file")}
														accept={".csv"}
													/>
													<TypographyWithLineBreaks variant={"body2"}>
														{i18n.t("import_shop_order_bom_dialog_helper_text")}
													</TypographyWithLineBreaks>
												</>
											),
										});
										if (formInput == null) return;
										await ShopOrderBomActionApi.importShopOrderBom({
											shopOrderId: shopOrderId,
											file: await uploadFile(formInput.file),
										});
									}}
								/>
							),
						]}
						rowContextMenuComponents={({ row }) => [
							editingEnabled && row.shopOrderLineState === "INITIAL" && !shopOrderIsClosed && (
								<AsyncMenuButton
									key={"release"}
									label={i18n.t("release")}
									icon={faShare}
									onClick={async () => {
										const confirmed = await showConfirmDialog({
											message: i18n.t("confirm_release_shop_order_line"),
										});
										if (!confirmed) return;

										await ShopOrderBomActionApi.releaseShopOrderLine({
											shopOrderLineId: onlySelectedRow!.shopOrderLineId,
											shopOrderLineLastUpdated: onlySelectedRow!.lastUpdated,
										});
										await refreshData();
									}}
								/>
							),
							editingEnabled && row.shopOrderLineState === "RELEASED" && (
								<AsyncMenuButton
									key={"revertRelease"}
									label={i18n.t("revert_release")}
									disabled={shopOrderIsAtLeastStarted}
									icon={faUndo}
									onClick={async () => {
										const confirmed = await showConfirmDialog({
											message: i18n.t("confirm_revert_release_shop_order_line"),
										});
										if (!confirmed) return;

										await ShopOrderBomActionApi.revertReleaseShopOrderLine({
											shopOrderLineId: row.shopOrderLineId,
											shopOrderLineLastUpdated: row.lastUpdated,
										});
										await refreshData();
									}}
								/>
							),
							<OpenSitePartButton partId={row.partId} key={"part"} />,
							row.acquisitionPurchaseOrderId != null && (
								<OpenPurchaseOrderButton
									key={"purchaseOrder"}
									purchaseOrderId={row.acquisitionPurchaseOrderId}
								/>
							),
							row.acquisitionShopOrderId != null && (
								<OpenShopOrderButton
									key={"openShopOrder"}
									shopOrderId={row.acquisitionShopOrderId}
								/>
							),
							row.shopOrderLineId != null && editingEnabled && (
								<AsyncMenuButton
									key={"cancel"}
									label={i18n.t("cancel")}
									icon={faBan}
									onClick={async () => {
										await cancelShopOrderBomLine({
											shopOrderLineId: row.shopOrderLineId,
											showConfirmDialog: showConfirmDialog,
										});
										await refreshData();
									}}
								/>
							),
							row.partIsConfigurable && editingEnabled && (
								<AsyncMenuButton
									key={"configure"}
									label={i18n.t("configure")}
									icon={faTools}
									disabled={row.shopOrderLineState !== "INITIAL"}
									onClick={async () => {
										openDialog(({ closeDialog }) => ({
											title: i18n.t("configure"),
											content: (
												<ShopOrderLineConfiguratorView
													shopOrderLineId={row.shopOrderLineId}
													closeDialog={closeDialog}
													refreshSourceView={refreshData}
												/>
											),
										}));
									}}
								/>
							),
							row.partConfigurationId != null && (
								<AsyncMenuButton
									key={"configuration"}
									label={i18n.t("configuration")}
									icon={faTable}
									onClick={() => {
										openDialog(() => ({
											title: i18n.t("configuration"),
											content: (
												<PartConfigurationInspectingView
													partConfigurationId={row.partConfigurationId!}
													showBom={true}
												/>
											),
										}));
									}}
								/>
							),
						]}
						form={{
							addRowEnabled: !shopOrderIsClosed && editingEnabled,
							editEnabled: !shopOrderIsClosed && editingEnabled,
							dialogSize: "sm",
							dialogTitle: i18n.t("shop_order_line"),
							component: ({ row, ...other }) => (
								<ShopOrderLineForm
									shopOrderId={shopOrderId}
									shopOrderLineId={row?.shopOrderLineId ?? null}
									{...other}
								/>
							),
						}}
						remove={{
							type: !shopOrderIsClosed && editingEnabled ? "enabled" : "disabled",
							action: async ({ items }) => {
								await ShopOrderBomActionApi.delete_({
									shopOrderLines: items,
								});
								await refreshData();
							},
						}}
						{...dataGridProps}
					/>
				);
			}}
		/>
	);
};
