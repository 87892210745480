import { JobQueueView } from "src/components/views/erp/production/jobQueue/base/JobQueueView.tsx";
import { ProductionLineJobQueueInitialsApi } from "src/api/generated/erp/production/api/jobQueues/productionLine/productionLineJobQueueInitialsApi.ts";
import { ProductionLineJobQueueInProgressApi } from "src/api/generated/erp/production/api/jobQueues/productionLine/productionLineJobQueueInProgressApi.ts";
import { ProductionLineJobQueueCompletedApi } from "src/api/generated/erp/production/api/jobQueues/productionLine/productionLineJobQueueCompletedApi.ts";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { faCheck, faExchangeAlt, faUndo } from "@fortawesome/pro-regular-svg-icons";
import i18n from "i18next";
import { useOpenLegacyView } from "src/components/views/legacy/useOpenLegacyView.ts";
import { openLegacyWorkcenterOperationActionView } from "src/components/views/legacy/legacyViewAdapters.ts";
import { useJobQueueViewContext } from "src/components/views/erp/production/jobQueue/base/JobQueueViewContext.ts";
import { SpcDataCollectionApi } from "src/api/generated/spc/dataCollection/spcDataCollectionApi.ts";
import { SelectedOperationActionsViewExtraComponentProps } from "src/components/views/erp/production/jobQueue/base/inProgressView/SelectedOperationActionsView.tsx";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { ProductionLineApi } from "src/api/generated/erp/production/api/productionLineApi.ts";
import { ProductionPlanningActionsApi } from "src/api/generated/erp/production/productionPlanning/api/productionPlanningActionsApi.ts";
import { JobQueueInitialsViewExtraRowContextComponentsProps } from "src/components/views/erp/production/jobQueue/base/JobQueueInitialsView.tsx";
import { useUserPermissions } from "src/components/views/erp/common/userPermissions.ts";

export const ProductionLineJobQueueView = () => {
	return (
		<JobQueueView
			viewId={"productionLineJobQueueView"}
			initialsProps={{
				fetchData: ProductionLineJobQueueInitialsApi.getInitialOperations,
				extraRowContextMenuComponents: (params) => <InitialsViewExtraRowContextComponents {...params} />,
				setProductionLineToOperationOnStart: true,
			}}
			inProgressProps={{
				fetchData: ProductionLineJobQueueInProgressApi.getInProgressOperations,
				extraActionBarComponents: (params) => <InProgressViewExtraActionBarComponents {...params} />,
				confirmIfWorkCenterHasIncompleteOperations: true,
			}}
			completedProps={{
				fetchData: ProductionLineJobQueueCompletedApi.getCompletedOperations,
			}}
		/>
	);
};

const InitialsViewExtraRowContextComponents = ({
	allSelectedRows,
	refreshData,
}: JobQueueInitialsViewExtraRowContextComponentsProps) => {
	const showInputDialog = useInputDialog();
	const userPermissions = useUserPermissions();
	return [
		userPermissions.production.setShopOrderProductionLine && (
			<AsyncMenuButton
				key={"changeProductionLine"}
				icon={faExchangeAlt}
				label={i18n.t("change_production_line")}
				disabled={allSelectedRows.length === 0}
				onClick={async () => {
					const productionLines = await ProductionLineApi.getProductionLines({
						siteId: allSelectedRows[0]!.siteId,
					});
					const newProductionLineId = await showInputDialog({
						type: "singleNumberSelect",
						title: i18n.t("change_production_line"),
						fieldLabel: i18n.t("new_production_line"),
						required: true,
						defaultValue: null,
						fieldProps: {
							selection: {
								options: productionLines.map((line) => ({
									value: line.productionLineId,
									label: line.productionLineName,
								})),
							},
						},
					});
					if (newProductionLineId === undefined) return;

					await ProductionPlanningActionsApi.setProductionLineForShopOrders({
						productionLineId: newProductionLineId,
						shopOrderIds: allSelectedRows.map((row) => row.shopOrderId),
					});
					await refreshData();
				}}
			/>
		),
	];
};

const InProgressViewExtraActionBarComponents = ({
	selectedOperation,
	workCenterControlChart,
}: SelectedOperationActionsViewExtraComponentProps) => {
	const openLegacyView = useOpenLegacyView();
	const { inProgressViewRefreshRef } = useJobQueueViewContext();

	return (
		<>
			<AsyncButton
				icon={faCheck}
				label={i18n.t("operation_receipt")}
				variant={"outlined"}
				onClick={() => {
					openLegacyWorkcenterOperationActionView({
						openLegacyView,
						shopOrderId: selectedOperation.shopOrderId,
						sourceRefData: selectedOperation.sourceRefData,
					});
				}}
			/>
			{workCenterControlChart &&
				(workCenterControlChart.hasIncompleteRecord ?
					<AsyncButton
						icon={faCheck}
						label={i18n.t("work_center_completed")}
						variant={"outlined"}
						onClick={async () => {
							await SpcDataCollectionApi.finishTimeBasedRecord({
								controlChartId: workCenterControlChart!.controlChartId,
							});
							await inProgressViewRefreshRef.refresh();
						}}
					/>
				:	<AsyncButton
						icon={faUndo}
						label={i18n.t("revert_work_center_completion")}
						variant={"outlined"}
						onClick={async () => {
							await SpcDataCollectionApi.revertLastRecordToIncomplete({
								controlChartId: workCenterControlChart!.controlChartId,
							});
							await inProgressViewRefreshRef.refresh();
						}}
					/>)}
		</>
	);
};
