import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import {
	ProductionLineDataGridApi,
	ProductionLineDataGridApi_ProductionLineDto,
} from "src/api/generated/erp/production/basedata/productionLines/api/productionLineDataGridApi";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { concatWithPipe } from "src/utils/strings.tsx";
import { ProductionLine } from "src/api/generated/erp/db/types/tables/productionLine.ts";
import { ProductionLineForm } from "src/components/views/erp/production/baseData/productionLines/ProductionLineFrom.tsx";
import { ProductionLineEditApi } from "src/api/generated/erp/production/basedata/productionLines/api/productionLineEditApi.ts";

export interface ProductionLinesDataGridProps {
	productionLineGroupId: number;
	onSelectionChanged: (selectedRow: ProductionLine | undefined) => void;
}

export const ProductionLinesDataGrid = ({
	productionLineGroupId,
	onSelectionChanged,
}: ProductionLinesDataGridProps) => {
	const { dataGridProps } = useClientSideDataGridModel({
		fetchData: () => ProductionLineDataGridApi.getDataGridData({ productionLineGroupId }),
		initialParams: {},
		gridId: "D2E1F092F012772A",
		getRowId: (row) => row.productionLineId,
		onSelectionChanged: (rows) => onSelectionChanged(rows[0]),
		selectFirstRowOnLoad: true
	});

	return (
		<ControlledAsyncCrudDataGrid<ProductionLineDataGridApi_ProductionLineDto>
			disableMultipleRowSelection
			columns={[
				textColumn({
					field: "productionLineName",
					headerName: i18n.t("name"),
					width: 200,
				}),
				textColumn({
					field: "capacityUnitName",
					headerName: i18n.t("capacity_unit"),
					width: 150,
				}),
				textColumn({
					field: "productionLineOperationNo",
					headerName: i18n.t("operation"),
					width: 200,
					valueGetter: (_, row) =>
						concatWithPipe(row.productionLineOperationNo, row.productionLineOperationDescription),
				}),
				textColumn({
					field: "taktTimeViewName",
					headerName: i18n.t("takt_time_view"),
					width: 200,
				}),
				integerColumn({
					field: "productionLineId",
					headerName: i18n.t("id"),
					width: 100,
				}),
			]}
			form={{
				addRowEnabled: true,
				editEnabled: true,
				dialogTitle: i18n.t("production_line"),
				dialogSize: "md",
				component: ({ row, onCompleted, onFormEdited }) => (
					<ProductionLineForm
						productionLineGroupId={productionLineGroupId}
						productionLineId={row?.productionLineId}
						onCompleted={onCompleted}
						onFormEdited={onFormEdited}
					/>
				),
			}}
			remove={({ items }) => ProductionLineEditApi.delete_({ productionLineId: items[0]!.productionLineId })}
			{...dataGridProps}
		/>
	);
};
