import { ConfigurationProductFamily } from "src/api/generated/erp/db/types/tables/configurationProductFamily.ts";
import { dateTimeColumn, enumColumn, integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import i18n from "i18next";
import { ConfigurationProductFamilyVersionApi } from "src/api/generated/erp/configurator/management/productFamilies/api/configurationProductFamilyVersionApi.ts";
import { ConfigurationProductFamilyVersionView } from "src/api/generated/erp/db/types/tables/configurationProductFamilyVersionView.ts";
import { getConfigurationProductFamilyVersionStateLabels } from "src/api/generated/erp/db/types/enums/configurationProductFamilyVersionState.ts";
import { faArrowUp91, faClone, faShare, faUndo } from "@fortawesome/pro-regular-svg-icons";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { useClientSideDataGridModel } from "src/components/common/dataGrid/gridModel/useClientSideDataGridModel.tsx";
import { useInputDialog } from "src/components/common/dialogs/input/useInputDialog.tsx";
import { ProductFamilyVersionManagingView } from "src/components/views/erp/configurator/managing/productFamilyVersions/ProductFamilyVersionManagingView.tsx";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid.tsx";
import { AsyncButton } from "src/components/common/buttons/AsyncButton.tsx";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { ProductFamilyVersionRevisionConnectionManagementView } from "src/components/views/erp/configurator/managing/productFamilyVersions/revisionConnections/ProductFamilyVersionRevisionConnectionManagementView.tsx";

export interface ConfigurationProductFamilyVersionsDataGridProps {
	productFamily: ConfigurationProductFamily;
}

export const ConfigurationProductFamilyVersionsDataGrid = ({
	productFamily,
}: ConfigurationProductFamilyVersionsDataGridProps) => {
	const showConfirmDialog = useConfirmDialog();
	const showInputDialog = useInputDialog();
	const { openDialog } = useGenericDialog();

	const { dataGridProps, refreshData, onlySelectedRow } = useClientSideDataGridModel({
		fetchData: () =>
			ConfigurationProductFamilyVersionApi.getProductFamilyVersions({
				productFamilyId: productFamily.configurationProductFamilyId,
			}),
		initialParams: {},
		getRowId: (row) => row.configurationProductFamilyVersionId,
		gridId: "246531113EC72791",
	});

	return (
		<ControlledAsyncCrudDataGrid<ConfigurationProductFamilyVersionView>
			columns={[
				integerColumn({
					field: "versionNumber",
					headerName: i18n.t("number_shortened"),
				}),
				enumColumn({
					field: "state",
					headerName: i18n.t("state"),
					enumLabels: getConfigurationProductFamilyVersionStateLabels(),
				}),
				textColumn({
					field: "description",
					headerName: i18n.t("description"),
					width: 150,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
					width: 150,
				}),
				dateTimeColumn({
					field: "createdAt",
					headerName: i18n.t("created_at"),
					width: 150,
				}),
				textColumn({
					field: "publishedByUserName",
					headerName: i18n.t("published_by"),
					width: 150,
				}),
				dateTimeColumn({
					field: "publishedAt",
					headerName: i18n.t("published_at"),
					width: 150,
				}),
				integerColumn({
					field: "configurationProductFamilyVersionId",
					headerName: i18n.t("id"),
				}),
			]}
			form={{
				addRowEnabled: false,
				editEnabled: true,
				dialogTitle: i18n.t("product_family_version"),
				component: ({ row, onFormDirtyStateChanged }) => {
					if (!row) return;
					return (
						<ProductFamilyVersionManagingView
							productFamilyVersion={row}
							onDirtyStateChanged={onFormDirtyStateChanged}
						/>
					);
				},
			}}
			actionBarComponents={
				<>
					<AsyncButton
						icon={faArrowUp91}
						label={i18n.t("part_revisions")}
						variant={"outlined"}
						disabled={!onlySelectedRow}
						onClick={() => {
							openDialog({
								title: i18n.t("part_revisions"),
								content: (
									<ProductFamilyVersionRevisionConnectionManagementView
										productFamilyVersionId={onlySelectedRow!.configurationProductFamilyVersionId}
									/>
								),
							});
						}}
					/>
				</>
			}
			rowContextMenuComponents={({ row }) => [
				row.state === "INITIAL" && (
					<AsyncMenuButton
						key={"publish"}
						icon={faShare}
						label={i18n.t("publish")}
						onClick={async () => {
							const confirmed = await showConfirmDialog({
								message: i18n.t("confirm_publish_configuration_product_family_version"),
							});
							if (!confirmed) return;

							await ConfigurationProductFamilyVersionApi.publishProductFamilyVersion({
								versionId: row.configurationProductFamilyVersionId,
							});
							await refreshData();
						}}
					/>
				),
				row.state === "PUBLISHED" && (
					<AsyncMenuButton
						key={"revertPublish"}
						icon={faUndo}
						label={i18n.t("revert_publish")}
						onClick={async () => {
							const confirmed = await showConfirmDialog({
								message: i18n.t("confirm_revert_publish_configuration_product_family_version"),
							});
							if (!confirmed) return;

							await ConfigurationProductFamilyVersionApi.revertPublishProductFamilyVersion({
								versionId: row.configurationProductFamilyVersionId,
							});
							await refreshData();
						}}
					/>
				),
				<AsyncMenuButton
					key={"clone"}
					icon={faClone}
					label={i18n.t("clone")}
					onClick={async () => {
						const newVersionDescription = await showInputDialog({
							type: "string",
							title: i18n.t("clone_product_family_version"),
							fieldLabel: i18n.t("new_version_description"),
							required: false,
							defaultValue: "",
						});
						if (newVersionDescription === undefined) return;

						await ConfigurationProductFamilyVersionApi.cloneProductFamilyVersion({
							sourceVersionId: row.configurationProductFamilyVersionId,
							newVersionDescription: newVersionDescription,
						});
						await refreshData();
					}}
				/>,
			]}
			{...dataGridProps}
		/>
	);
};
