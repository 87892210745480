import { SurveyFormsDataGridApi } from "src/api/generated/erp/surveys/api/surveyFormsDataGridApi";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import i18n from "i18next";
import { SurveyFormView } from "src/api/generated/erp/db/types/tables/surveyFormView.ts";
import { SurveyFormEditApi } from "src/api/generated/erp/surveys/api/surveyFormEditApi.ts";
import { SurveyFormForm } from "src/components/views/erp/surveys/management/forms/SurveyFormForm.tsx";
import { integerColumn, textColumn } from "src/components/common/dataGrid/columns.tsx";
import { CrudDataGrid } from "src/components/common/dataGrid/crud/CrudDataGrid.tsx";
import { ServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/ServerSideDataGridModel.tsx";
import { SelectField } from "src/components/common/inputFields/SelectField.tsx";
import { SurveyFormSubmissionsDataGrid } from "src/components/views/erp/surveys/management/forms/SurveyFormSubmissionsDataGrid.tsx";
import { faList } from "@fortawesome/pro-regular-svg-icons";
import { useGenericDialog } from "src/components/common/dialogs/useGenericDialog.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";

export interface SurveyFormsDataGridProps {
	onSelectionChanged: (selectedRow: SurveyFormView | undefined) => void;
}

export const SurveyFormsDataGrid = ({ onSelectionChanged }: SurveyFormsDataGridProps) => {
	const { openDialog } = useGenericDialog();

	return (
		<ServerSideDataGridModel
			fetchData={(params) => SurveyFormsDataGridApi.getDataGridData(params)}
			initialParams={{
				categoryId: genericNullableValue<number>(),
			}}
			getDataModelResult={(response) => response.data}
			getRowId={(row) => row.surveyFormId}
			gridId="C826058BD93A764C"
			onSelectionChanged={(rows) => onSelectionChanged(rows[0])}
			render={({ dataGridProps, currentParams, refreshData, data: { categoryFilterOptions } }) => (
				<CrudDataGrid<SurveyFormView>
					disableMultipleRowSelection
					columns={[
						textColumn({
							field: "name",
							headerName: i18n.t("name"),
							width: 200,
						}),
						textColumn({
							field: "description",
							headerName: i18n.t("description"),
							width: 200,
						}),
						textColumn({
							field: "categoryName",
							headerName: i18n.t("category"),
							width: 200,
						}),
						integerColumn({
							field: "surveyFormId",
							headerName: i18n.t("id"),
						}),
					]}
					actionBarComponents={
						<>
							<SelectField
								label={i18n.t("category")}
								options={categoryFilterOptions}
								getOptionKey={(option) => option.surveyCategoryId}
								getOptionLabel={(option) => option.name}
								value={currentParams.categoryId}
								onChange={(value) => refreshData({ categoryId: value })}
							/>
						</>
					}
					rowContextMenuComponents={({ row }) => [
						<AsyncMenuButton
							key={"openSubmissions"}
							label={i18n.t("surveys")}
							icon={faList}
							onClick={() => {
								openDialog({
									title: row.name,
									size: "xl",
									content: <SurveyFormSubmissionsDataGrid surveyFormId={row.surveyFormId} />,
								});
							}}
						/>,
					]}
					form={{
						addRowEnabled: true,
						editEnabled: true,
						dialogTitle: i18n.t("survey_form"),
						dialogSize: "sm",
						component: ({ row, onCompleted, onFormEdited }) => (
							<SurveyFormForm
								surveyFormId={row?.surveyFormId}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						),
					}}
					remove={({ row }) => SurveyFormEditApi.delete_({ surveyFormId: row.surveyFormId })}
					{...dataGridProps}
				/>
			)}
		/>
	);
};
