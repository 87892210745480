// This file is automatically generated. Do not edit manually.

import { ServerSideDataModelRequest } from "src/api/generated/common/dataGrids/serverSideDataModelRequest";
import { SurveyCategory } from "src/api/generated/erp/db/types/tables/surveyCategory";
import { ServerSideDataModelResult } from "src/api/generated/common/dataGrids/serverSideDataModelResult";
import { SurveyFormView } from "src/api/generated/erp/db/types/tables/surveyFormView";
import { makeAxiosRequest } from "src/api/utils/apiClient";

export namespace SurveyFormsDataGridApi {
	export async function getDataGridData(args: {
		categoryId: number | null | undefined;
		dataModelRequest: ServerSideDataModelRequest;
	}): Promise<SurveyFormsDataGridApi_GridData> {
		const response = await makeAxiosRequest({
			url: "/api/ext/erp/surveys/management/surveyFormDataGrid/getDataGridData",
			method: "POST",
			data: {
				categoryId: args.categoryId,
				dataModelRequest: args.dataModelRequest,
			},
		});
		return response as SurveyFormsDataGridApi_GridData;
	}
}

export interface SurveyFormsDataGridApi_GridData {
	categoryFilterOptions: Array<SurveyCategory>;
	data: ServerSideDataModelResult<SurveyFormView>;
}
