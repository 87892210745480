import { useServerSideDataGridModel } from "src/components/common/dataGrid/gridModel/useServerSideDataGridModel";
import { CustomerOrderDeliveryQueryApi } from "src/api/generated/erp/sales/delivery/api/customerOrderDeliveryQueryApi.ts";
import { ControlledAsyncCrudDataGrid } from "src/components/common/dataGrid/crud/ControlledAsyncCrudDataGrid";
import { genericNullableValue } from "src/utils/genericNullableValue.ts";
import {
	dateColumn,
	dateTimeColumn,
	enumColumn,
	integerColumn,
	textColumn,
} from "src/components/common/dataGrid/columns";
import {
	getCustomerOrderDeliveryStateLabel,
	getCustomerOrderDeliveryStateLabels,
} from "src/api/generated/erp/db/types/enums/customerOrderDeliveryState";
import i18n from "i18next";
import { CustomerOrderDeliveryView } from "src/api/generated/erp/db/types/tables/customerOrderDeliveryView";
import { formatDayJs } from "src/utils/dayjsUtils.ts";
import { getCountryLabels } from "src/api/generated/erp/db/types/enums/country.ts";
import { HorizontalBox } from "src/components/common/box/HorizontalBox.tsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material";
import { faBan, faCheck, faHourglassHalf, faTruck } from "@fortawesome/pro-regular-svg-icons";
import { CustomerOrderDeliveryActionApi } from "src/api/generated/erp/sales/delivery/api/customerOrderDeliveryActionApi.ts";
import { useConfirmDialog } from "src/components/common/dialogs/confirmDialog/useConfirmDialog.ts";
import { AsyncMenuButton } from "src/components/common/contextMenu/AsyncMenuButton.tsx";
import { ReactNode, useContext } from "react";
import { CustomerOrdersPageContext } from "src/components/views/erp/sales/customerOrder/CustomerOrdersPageContext.tsx";
import { CustomerOrderDeliveryForm } from "src/components/views/erp/sales/delivery/CustomerOrderDeliveryForm.tsx";
import { CustomerOrderDeliveryEditApi } from "src/api/generated/erp/sales/delivery/api/customerOrderDeliveryEditApi.ts";
import {
	PrintCustomerOrderDeliveryConsignmentNoteButton,
	PrintCustomerOrderDeliveryNoteButton,
} from "src/components/views/erp/sales/delivery/customerOrderDeliveryButtons.tsx";
import { CustomerOrderDeliveryFormWithPackages } from "src/components/views/erp/sales/delivery/CustomerOrderDeliveryFormWithPackages.tsx";
import { AavoTextField } from "src/components/common/inputFields/AavoTextField.tsx";
import { AsyncSelectField } from "src/components/common/inputFields/AsyncSelectField.tsx";
import { CustomerApi } from "src/api/generated/erp/sales/customer/api/customerApi.ts";
import { Customer } from "src/api/generated/erp/db/types/tables/customer.ts";
import { MenuCheckbox } from "src/components/common/contextMenu/MenuCheckbox.tsx";
import { useFeatureChangeHint } from "src/components/common/featureChangeHint/useFeatureChangeHint.ts";
import dayjs from "dayjs";
import { OpenCustomerOrderButton } from "src/components/views/erp/utilComponents/OpenCustomerOrderButton.tsx";

export interface CustomerOrderDeliveriesDataGridBaseProps {
	customerOrderId?: number;
	onSelectionChanged?: (selectedDelivery: CustomerOrderDeliveryView | undefined) => void;
	showSearchField?: boolean;
	showCustomerFilter?: boolean;
	showOnlyUndeliveredByDefault?: boolean;
	actionBarExtraComponents?: ReactNode;
	onlyCustomerOrderDeliveryId?: number;
}

export const CustomerOrderDeliveriesDataGridBase = ({
	customerOrderId,
	onSelectionChanged,
	showSearchField,
	showCustomerFilter,
	showOnlyUndeliveredByDefault,
	actionBarExtraComponents = null,
	onlyCustomerOrderDeliveryId,
}: CustomerOrderDeliveriesDataGridBaseProps) => {
	const customerOrdersRefreshRef = useContext(CustomerOrdersPageContext)?.customerOrdersRefreshRef;

	const { dataGridProps, refreshData, currentParams } = useServerSideDataGridModel({
		gridId: "C7FB309364222767",
		fetchData: (params) =>
			CustomerOrderDeliveryQueryApi.searchCustomerOrderDeliveries({
				customerOrderId: customerOrderId,
				searchQuery: params.searchQuery,
				customerId: params.customerId,
				onlyDefaultSite: params.onlyDefaultSite,
				onlyUndelivered: params.onlyUndelivered,
				dataModelRequest: params.dataModelRequest,
				onlyCustomerOrderDeliveryId: onlyCustomerOrderDeliveryId,
			}),
		initialParams: {
			searchQuery: "",
			customerId: genericNullableValue<number>(),
			onlyUndelivered: showOnlyUndeliveredByDefault,
			onlyDefaultSite: customerOrderId == null,
		},
		getRowId: (row) => row.customerOrderDeliveryId,
		usePagination: customerOrderId == null,
		onSelectionChanged: (selectedDeliveries) => onSelectionChanged?.(selectedDeliveries[0]),
		selectFirstRowOnLoad: onlyCustomerOrderDeliveryId != null,
	});

	const showConfirmDialog = useConfirmDialog();
	const { palette } = useTheme();

	const menuButtonRef = useFeatureChangeHint<HTMLButtonElement>({
		key: "30B17F41AC0357BA",
		hideAfter: dayjs("2024-06-01"),
		content: `Toimituksen toiminnot löydät jatkossa klikkaamalla toimitusta hiiren oikealla painikkeella`,
	});

	return (
		<ControlledAsyncCrudDataGrid<CustomerOrderDeliveryView>
			disableMultipleRowSelection={true}
			columns={[
				integerColumn({
					field: "customerOrderDeliveryId",
					headerName: i18n.t("number_shortened"),
					width: 80,
				}),
				customerOrderId == null && [
					integerColumn({
						field: "customerOrderId",
						headerName: i18n.t("customer_order_number_short"),
						width: 80,
					}),
					textColumn({
						field: "customerName",
						headerName: i18n.t("customer"),
						width: 120,
					}),
					textColumn({
						field: "siteName",
						headerName: i18n.t("site"),
					}),
					textColumn({
						field: "orderReference",
						headerName: i18n.t("order_reference"),
						width: 200,
					}),
				],
				enumColumn({
					field: "deliveryState",
					headerName: i18n.t("status"),
					enumLabels: getCustomerOrderDeliveryStateLabels(),
					renderCell: ({ row }) => (
						<HorizontalBox alignItems="center" gap={1}>
							<FontAwesomeIcon
								icon={row.deliveryState === "DELIVERED" ? faCheck : faHourglassHalf}
								color={row.deliveryState === "DELIVERED" ? palette.success.main : palette.grey[600]}
							/>
							{getCustomerOrderDeliveryStateLabel(row.deliveryState)}
						</HorizontalBox>
					),
				}),
				dateColumn({
					field: "actualDeliveryDate",
					headerName: i18n.t("delivered_at"),
				}),
				textColumn({
					field: "cargoDriverName",
					headerName: i18n.t("driver"),
				}),
				dateColumn({
					field: "plannedLoadingDate",
					headerName: i18n.t("planned_loading_date"),
					width: 160,
					valueFormatter: (value) => `${formatDayJs(value)} (${i18n.t("week_abbr")} ${value.week()})`,
				}),
				dateColumn({
					field: "plannedUnloadingDate",
					headerName: i18n.t("planned_unloading_date"),
					width: 160,
				}),
				textColumn({
					field: "trackingCode",
					headerName: i18n.t("tracking_code"),
					width: 130,
				}),
				textColumn({
					field: "note",
					headerName: i18n.t("note"),
					width: 130,
				}),
				textColumn({
					field: "deliveryName",
					headerName: i18n.t("address_name"),
					width: 130,
				}),
				textColumn({
					field: "deliveryAddress_1",
					headerName: i18n.t("delivery_address"),
					width: 150,
				}),
				textColumn({
					field: "deliveryPostalCode",
					headerName: i18n.t("postal_code"),
				}),
				textColumn({
					field: "deliveryCity",
					headerName: i18n.t("city"),
				}),
				enumColumn({
					field: "deliveryCountry",
					headerName: i18n.t("country"),
					enumLabels: getCountryLabels(),
				}),
				textColumn({
					field: "senderContact",
					headerName: i18n.t("sender_contact"),
					width: 170,
				}),
				textColumn({
					field: "recipientContact",
					headerName: i18n.t("recipient_contact"),
					width: 180,
				}),
				dateTimeColumn({
					field: "createdDate",
					headerName: i18n.t("created_at"),
					width: 140,
				}),
				textColumn({
					field: "createdByUserName",
					headerName: i18n.t("created_by"),
				}),
			]}
			actionBarComponents={
				<>
					{showSearchField && (
						<AavoTextField
							label={i18n.t("search")}
							onSubmit={(searchQuery) => refreshData({ searchQuery })}
						/>
					)}
					{showCustomerFilter && (
						<AsyncSelectField
							label={i18n.t("customer")}
							fetchOptions={({ searchQuery, currentSelection }) =>
								CustomerApi.getCustomerSelectionOptions({
									searchQuery,
									currentSelection,
								})
							}
							getOptionKey={(o: Customer) => o.customerId}
							getOptionLabel={(o) => o.customerName}
							onChange={(customer) => refreshData({ customerId: customer?.customerId })}
						/>
					)}
					{actionBarExtraComponents}
				</>
			}
			actionBarMenuComponents={[
				showOnlyUndeliveredByDefault && (
					<MenuCheckbox
						key={"showDelivered"}
						label={i18n.t("show_delivered")}
						checked={!currentParams.onlyUndelivered}
						onChange={(value) => refreshData({ onlyUndelivered: !value })}
					/>
				),
			]}
			actionBarMenuButtonRef={menuButtonRef}
			rowContextMenuComponents={({ row, onlySingleRowSelected }) => [
				customerOrderId == null && (
					<OpenCustomerOrderButton key={"showCustomerOrder"} customerOrderId={row.customerOrderId} />
				),
				<PrintCustomerOrderDeliveryConsignmentNoteButton
					key={"printConsignmentNote"}
					variant={"menu"}
					delivery={row}
				/>,
				<PrintCustomerOrderDeliveryNoteButton key={"printDeliveryNote"} variant={"menu"} delivery={row} />,
				onlySingleRowSelected && row?.deliveryState === "INITIAL" && (
					<AsyncMenuButton
						key={"markToDelivered"}
						icon={faTruck}
						label={i18n.t("mark_delivered")}
						onClick={async () => {
							if (row?.customerOrderDeliveryId == null) return;
							const confirmed = await showConfirmDialog();
							if (!confirmed) return;

							await CustomerOrderDeliveryActionApi.setCustomerOrderDeliveryToDelivered({
								customerOrderDeliveryId: row.customerOrderDeliveryId,
							});
							await Promise.all([refreshData(), customerOrdersRefreshRef?.refresh()]);
						}}
					/>
				),
				onlySingleRowSelected && row?.deliveryState === "DELIVERED" && (
					<AsyncMenuButton
						key={"markToUndelivered"}
						icon={faBan}
						label={i18n.t("return_to_initial_state")}
						onClick={async () => {
							if (row?.customerOrderDeliveryId == null) return;
							const confirmed = await showConfirmDialog({
								cancelButtonText: i18n.t("no"),
							});
							if (!confirmed) return;

							await CustomerOrderDeliveryActionApi.revertCustomerOrderDelivery({
								customerOrderDeliveryId: row.customerOrderDeliveryId,
							});
							await Promise.all([refreshData(), customerOrdersRefreshRef?.refresh()]);
						}}
					/>
				),
			]}
			form={{
				addRowEnabled: customerOrderId != null,
				editEnabled: true,
				dialogTitle: i18n.t("delivery"),
				dialogSize: (row) => (row == null ? "xl" : "fullscreen"),
				component: ({ row, onCompleted, onFormEdited }) => {
					const customerOrderIdToUse = customerOrderId ?? row?.customerOrderId;
					if (customerOrderIdToUse == null) return null;

					if (row == null)
						return (
							<CustomerOrderDeliveryForm
								customerOrderId={customerOrderIdToUse}
								onCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						);
					else
						return (
							<CustomerOrderDeliveryFormWithPackages
								customerOrderDelivery={row}
								onFormCompleted={onCompleted}
								onFormEdited={onFormEdited}
							/>
						);
				},
			}}
			remove={({ items }) =>
				CustomerOrderDeliveryEditApi.delete_({
					customerOrderDeliveryIds: items.map((item) => item.customerOrderDeliveryId),
				})
			}
			{...dataGridProps}
		/>
	);
};
