import { useState } from "react";
import { ProjectActivityCustomerOrderView } from "src/api/generated/erp/db/types/tables/projectActivityCustomerOrderView.ts";
import { getOnlyMember } from "src/utils/arrayUtils.ts";
import { CenteredTypography } from "src/components/common/CenteredTypography.tsx";
import i18n from "i18next";
import { AavoMosaic } from "src/components/common/mosaic/AavoMosaic.tsx";
import { ProjectActivityJobQueueInProgressDataGrid } from "src/components/views/erp/project/activityJobQueue/ProjectActivityJobQueueInProgressDataGrid.tsx";
import { SingleSourceTasksView } from "src/components/views/tasks/pages/SingleSourceTasksView.tsx";
import { CostEventsOfObjectDataGrid } from "src/components/views/erp/common/costEvents/CostEventsOfObjectDataGrid.tsx";
import { CustomerOrderInfoAndCommentsView } from "src/components/views/erp/sales/customerOrder/CustomerOrderInfoAndCommentsView.tsx";
import { DocumentsOfSingleObjectView } from "src/components/views/documents/objectDocuments/DocumentsOfSingleObjectView.tsx";
import { SingleActivityMaterialLinesDataGrid } from "src/components/views/erp/project/projectActivity/material/SingleActivityMaterialLinesDataGrid.tsx";

export const ProjectActivityJobQueueInProgressDesktopView = () => {
	const [selectedActivities, setSelectedActivities] = useState<ProjectActivityCustomerOrderView[]>([]);

	const onlySelectedActivity = getOnlyMember(selectedActivities);
	const selectSingleRowPlaceholder = <CenteredTypography children={i18n.t("select_a_single_activity")} />;

	return (
		<AavoMosaic
			viewId={"F3A7E1A46BD3C2"}
			layout={{
				type: "column",
				first: {
					type: "panel",
					title: i18n.t("in_progress"),
					content: (
						<ProjectActivityJobQueueInProgressDataGrid
							onSelectionChanged={setSelectedActivities}
						/>
					),
				},
				second: {
					type: "tabs",
					items: [
						{
							type: "panel",
							title: i18n.t("tasks"),
							content:
								onlySelectedActivity == null ? selectSingleRowPlaceholder : (
									<SingleSourceTasksView
										key={onlySelectedActivity.activityId}
										taskSourceRef={{
											sourceType: "PROJECT_ACTIVITY",
											sourceId: onlySelectedActivity.activityId,
										}}
									/>
								),
						},
						{
							type: "panel",
							title: i18n.t("materials"),
							content:
								onlySelectedActivity == null ? selectSingleRowPlaceholder : (
									<SingleActivityMaterialLinesDataGrid
										key={onlySelectedActivity.activityId}
										activityId={onlySelectedActivity.activityId}
									/>
								),
						},
						{
							type: "panel",
							title: i18n.t("activity_documents"),
							content:
								onlySelectedActivity == null ? selectSingleRowPlaceholder : (
									<DocumentsOfSingleObjectView
										key={onlySelectedActivity.activityId}
										objectRef={{
											objectType: "PROJECT_ACTIVITY",
											objectId: onlySelectedActivity.activityId,
										}}
										editable
										hidePreview
									/>
								),
						},
						onlySelectedActivity?.costEventPriceListId != null && {
							type: "panel",
							title: i18n.t("cost_events"),
							content: (
								<CostEventsOfObjectDataGrid
									key={onlySelectedActivity.activityId}
									objectRef={{
										objectType: "PROJECT_ACTIVITY",
										objectId: onlySelectedActivity.activityId,
									}}
									costEventPriceListId={onlySelectedActivity.costEventPriceListId}
								/>
							),
						},
						onlySelectedActivity?.customerOrderId != null && {
							type: "panel",
							title: i18n.t("customer_order_documents"),
							content: (
								<DocumentsOfSingleObjectView
									key={onlySelectedActivity.customerOrderId}
									objectRef={{
										objectType: "CUSTOMER_ORDER",
										objectId: onlySelectedActivity.customerOrderId,
									}}
									editable
									hidePreview
								/>
							),
						},
						onlySelectedActivity?.customerOrderId != null && {
							type: "panel",
							title: i18n.t("customer_order_info"),
							content: (
								<CustomerOrderInfoAndCommentsView
									key={onlySelectedActivity.customerOrderId}
									customerOrderId={onlySelectedActivity.customerOrderId}
								/>
							),
						},
					],
				},
			}}
		/>
	);
};
