import i18n from "i18next";
import {ShopOrderApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderApi.ts";
import {ShopOrderActionApi} from "src/api/generated/erp/production/api/shopOrder/shopOrderActionApi.ts";
import {ShowConfirmDialogFunc} from "src/components/common/dialogs/confirmDialog/ConfirmDialogProvider.tsx";
import {PurchaseOrderLine} from "src/api/generated/erp/db/types/tables/purchaseOrderLine.ts";

export async function closeShopOrder({
	shopOrderId,
	showConfirmDialog,
}: {
	shopOrderId: number;
	showConfirmDialog: ShowConfirmDialogFunc;
}) {
	const closeShopOrderConfirmed = await showConfirmDialog({
		title: i18n.t("close"),
		message: i18n.t("confirm_close_shop_order"),
	});
	if (!closeShopOrderConfirmed) return;

	const incompleteShopOrderOperations = await ShopOrderApi.getIncompleteOperationsOfShopOrder({
		shopOrderId: shopOrderId,
	});
	if (incompleteShopOrderOperations.length > 0) {
		const incompleteOperationsConfirmed = await showConfirmDialog({
			title: i18n.t("are_you_sure"),
			message: i18n.t("close_shop_order.incomplete_operations_warning", {
				operations: incompleteShopOrderOperations.map((o) => o.operationLongName).join(", "),
			}),
		});
		if (!incompleteOperationsConfirmed) return false;
	}

	const acquisitionObjects = await ShopOrderApi.getShopOrdersAcquisitionObjects({
		shopOrderIds: [shopOrderId],
	});
	const openPurchaseOrderLines = filterOpenPurchaseOrderLines(acquisitionObjects.purchaseOrderLines);
	const openShopOrders = acquisitionObjects.shopOrders.filter(
		(shopOrder) => shopOrder.shopOrderState !== "READY" && shopOrder.shopOrderState !== "CANCELLED",
	);

	if (openShopOrders.length > 0 || openPurchaseOrderLines.length > 0) {
		const acquisitionObjectsConfirmed = await showConfirmDialog({
			title: i18n.t("are_you_sure"),
			message: i18n.t("close_shop_order.shop_order_has_open_acquisition_objects_warning", {
				shop_order_ids: openShopOrders.map((s) => s.shopOrderId).join(", "),
				purchase_order_ids: openPurchaseOrderLines.map((p) => p.purchaseOrderId).join(", "),
			}),
		});
		if (!acquisitionObjectsConfirmed) return;
	}

	await ShopOrderActionApi.closeShopOrder({
		shopOrderId: shopOrderId,
	});
}

function filterOpenPurchaseOrderLines(purchaseOrderLines: PurchaseOrderLine[]): PurchaseOrderLine[] {
	return purchaseOrderLines.filter(
		(line) => line.purchaseOrderLineState !== "RECEIVED" && line.purchaseOrderLineState !== "CANCELLED",
	);
}
